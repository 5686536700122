import { BehaviorSubject } from 'rxjs'

import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { localStorageLanguageKey } from '../data/constants'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  currentLanguage$ = new BehaviorSubject<string>(
    this.translateService.currentLang
  )

  constructor(public translateService: TranslateService) {}

  changeAppLanguage(language: string) {
    this.translateService.use(language)
    this.currentLanguage$.next(language)
    localStorage.setItem(localStorageLanguageKey, language)
  }
}
